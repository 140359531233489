import { type ActionOptionsWithPayload, type ComposerContextType } from "./ComposerContextType";
const ComposerReducer = (state: ComposerContextType, action: ActionOptionsWithPayload): ComposerContextType => {
  switch (action.type) {
    case "UPDATE_FROM":
      {
        if (action.payload.length > 1) {
          return state;
        }
        return {
          ...state,
          from: action.payload
        };
      }
    case "UPDATE_SUBJECT":
      return {
        ...state,
        subject: action.payload
      };
    case "UPDATE_BODY":
      return {
        ...state,
        body: action.payload
      };
    case "UPDATE_TO":
      return {
        ...state,
        to: [...action.payload]
      };
    case "UPDATE_CC":
      return {
        ...state,
        cc: [...action.payload]
      };
    case "UPDATE_BCC":
      return {
        ...state,
        bcc: [...action.payload]
      };
    case "RESET":
      {
        return action.payload;
      }
    case "SET_COMPOSE_TYPE":
      {
        return {
          ...state,
          composeType: action.payload
        };
      }
    default:
      return state;
  }
};
export default ComposerReducer;