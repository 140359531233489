import type { UserContact, RepoContact, TypedContact } from "@openqlabs/drm-db";
import React, { createContext, useCallback, useContext, useState, type Dispatch, type SetStateAction } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { api, type RouterOutputs } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useContact } from "./ContactProvider";
type RefetchFunc = () => Promise<void>;
type ContactOpts = RepoContact | UserContact | null;
const GithubContactContext = createContext<{
  githubContact: ContactOpts;
  refetchContact: RefetchFunc;
  isEvaluating: boolean;
  setIsEvaluating: Dispatch<SetStateAction<boolean>>;
  contactWithChildEvaluations: RouterOutputs["githubContact"]["getWithChildEvaluations"];
} | object>({});
export function useGithubContact<T>() {
  const context = ((useContext(GithubContactContext) as unknown) as {
    githubContact: TypedContact<T> | null;
    refetchContact: RefetchFunc;
    isEvaluating: boolean;
    setIsEvaluating: Dispatch<SetStateAction<boolean>>;
    contactWithChildEvaluations: RouterOutputs["githubContact"]["getWithChildEvaluations"];
  });
  return context;
}
interface ProviderProps {
  githubContactId?: string;
  initialContact?: ContactOpts;
  children: React.ReactNode;
}
export function GithubContactProvider({
  children,
  initialContact = null
}: ProviderProps) {
  const logger = useLogger();
  if (!initialContact) logger.info("no initial contact", "GithubContactProvider.tsx");
  const [isEvaluating, setIsEvaluating] = useState(false);
  const contact = useContact();
  const githubContactId = contact?.contact?.githubContactId;
  const {
    activeTeamAccount
  } = useTeamAccount();
  const {
    refetch,
    data: githubContact
  } = api.githubContact.get.useQuery({
    githubContactId,
    teamAccountId: activeTeamAccount.id
  });
  const {
    data: contactWithChildEvaluations
  } = api.githubContact.getWithChildEvaluations.useQuery({
    githubContactId,
    teamAccountId: activeTeamAccount.id
  });
  const refetchContact = useCallback(async () => {
    await refetch();
  }, [refetch]);
  return <GithubContactContext.Provider value={{
    githubContact,
    contactWithChildEvaluations,
    refetchContact,
    isEvaluating,
    setIsEvaluating
  }} data-sentry-element="unknown" data-sentry-component="GithubContactProvider" data-sentry-source-file="GithubContactProvider.tsx">
      {children}
    </GithubContactContext.Provider>;
}