import { useRouter } from "next/router";
const useId = (key: string, propId?: string) => {
  const router = useRouter();
  const {
    query
  } = router;
  const id = query[key];
  if (typeof id === "string") {
    return id;
  }
  if (propId) {
    return propId;
  }
  null;
};
export default useId;