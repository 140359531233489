import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
export default function LineHeader({
  title,
  parentValue,
  allowMultiple,
  setClosed,
  dispatchCreator
}: {
  title: string;
  parentValue: string[];
  allowMultiple?: boolean;
  setClosed?: (closed: boolean) => void;
  dispatchCreator: (value: string[]) => void;
}) {
  const [value, setValue] = useState(parentValue.join(""));
  const [pills, setPills] = useState<string[]>([]);
  const simpleEmailRegexToCatchPreceding = /(\S+)@\S+\s/g;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!allowMultiple && pills.length > 0) return;
    const value = e.target.value;
    setValue(e.target.value);
    if (simpleEmailRegexToCatchPreceding.test(value)) {
      const matches = value.match(simpleEmailRegexToCatchPreceding);
      if (matches === null) return;
      dispatchCreator([...parentValue, value.trim()]);
      setValue("");
    }
  };
  useEffect(() => {
    const simpleEmailRegexToCatchPreceding = /(\S+)@\S+/g;
    const trimmedEmails = parentValue.map(email => {
      const trimmedEmail = email.replace(simpleEmailRegexToCatchPreceding, "$1");
      setValue("");
      return trimmedEmail;
    });
    setPills(trimmedEmails);
  }, [parentValue]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && value === "") {
      dispatchCreator(parentValue.slice(0, parentValue.length - 1));
    }
    if (e.key === "Enter") {
      dispatchCreator([...parentValue, value.trim()]);
      setValue("");
    }
  };
  const handleUnFocus = () => {
    dispatchCreator([...parentValue, value.trim()]);
    setValue("");
  };
  const handleClose = () => {
    if (setClosed) {
      setClosed(true);
    }
    dispatchCreator([]);
  };
  return <div className="flex flex-wrap py-2" data-sentry-component="LineHeader" data-sentry-source-file="LineHeader.tsx">
      <span className="w-16 font-bold">{title}:</span>
      {pills.map((pill, index) => <span key={pill + index.toString()} className="py-0.25 rounded-full bg-offwhite px-1 font-semibold">
          {pill}
        </span>)}
      <input onChange={handleChange} onKeyDown={handleKeyDown} onBlur={handleUnFocus} value={value} className="inline flex-1 text-sm text-muted focus-visible:outline-none" />
      {setClosed && <button onClick={handleClose}>
          <XMarkIcon className="h-6 w-6" />
        </button>}
    </div>;
}