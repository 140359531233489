import { createContext, useContext, type ReactNode } from "react";
import useId from "~/hooks/useId";
import { RouterOutputs, api } from "~/utils/api";
import { useLogger } from "./LoggerProvider";
import { useTeamAccount } from "./TeamAccountProvider";
export type TeamRenderContact = RouterOutputs["listContact"]["getContactsByListId"]["items"][number] | null;
export type TeamRenderContactDeep = RouterOutputs["listContact"]["get"] | null;
const contactContext = createContext<{
  contact: TeamRenderContactDeep;
  isLoading: boolean;
  updateContact: (contact: Partial<TeamRenderContactDeep>) => void;
  setContact: (contact: TeamRenderContactDeep) => void;
  refetch: () => void;
}>({
  contact: null,
  isLoading: true,
  updateContact: () => {
    return null;
  },
  setContact: () => {
    return null;
  },
  refetch: () => {
    return null;
  }
});
export const useContact = () => {
  const context = useContext(contactContext);
  return {
    ...context,
    contact: (context.contact as NonNullable<TeamRenderContactDeep>)
  };
};
export const ContactProvider = ({
  children,
  id,
  hasPlaceholder
}: {
  children: ReactNode | ReactNode[];
  id?: string;
  hasPlaceholder?: boolean;
}) => {
  const currentId = useId("contactId", id);
  const logger = useLogger();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const teamAccountId = activeTeamAccount?.id ?? "";
  const utils = api.useUtils();
  const handleUpdateContact = (contact: Partial<TeamRenderContactDeep>) => {
    utils.listContact.get.setData({
      teamAccountId,
      id: (contact?.id as string)
    }, (contact as RouterOutputs["listContact"]["get"]));
  };
  const {
    mutate: updateContact
  } = api.listContact.update.useMutation({
    onSuccess: data => {
      handleUpdateContact(data);
    }
  });
  const {
    data: contact,
    refetch,
    isLoading
  } = api.listContact.get.useQuery({
    teamAccountId: teamAccountId,
    id: currentId
  });
  const handleRefetch = () => {
    refetch().catch(err => logger.error(err, "ContactProvider.tsx"));
  };
  const contactUpdater = (contact: Partial<TeamRenderContactDeep>) => {
    if (!contact) throw new Error("statefulContact is null");
    updateContact({
      ...contact,
      id: (contact?.id as string),
      teamAccountId: teamAccountId
    });
  };
  if (!contact) {
    if (hasPlaceholder) {
      return children;
    }
    return <></>;
  } else return <contactContext.Provider value={{
    contact,
    setContact: handleUpdateContact,
    updateContact: contactUpdater,
    refetch: handleRefetch,
    isLoading
  }}>
        {children}
      </contactContext.Provider>;
};