import { Dialog } from "@headlessui/react";
import { useContext, useState } from "react";
import DropDown from "~/components/base/DropDown";
import ChevronCorrectIcon from "~/components/layout/ChevronCorrectIcon";
import ComposerContext from "./ComposerStore/ComposerContext";
import ComposerProvider from "./ComposerStore/ComposerProvider";
import ComposeHeaders from "./ComposerUI/ComposeHeaders";
import ComposerBody from "./ComposerUI/ComposerBody";
import SendButton from "./ComposerUI/SendButton";
const ComposerUI = ({
  refetch
}: {
  refetch: () => void;
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const items = [{
    name: "Note"
  }];
  const {
    state,
    dispatch
  } = useContext(ComposerContext);
  const {
    composeType
  } = state;
  const setComposeType = (composeType: string) => {
    dispatch({
      type: "SET_COMPOSE_TYPE",
      payload: composeType
    });
  };
  const selectItem = (name: string) => {
    setComposeType(name);
  };
  return <Dialog open={composeType !== ""} onClose={() => setComposeType("")} data-sentry-element="Dialog" data-sentry-component="ComposerUI" data-sentry-source-file="index.tsx">
      <div className={` fixed bottom-0 right-0 z-50 flex h-[100vh]   w-[600px] items-center justify-center p-4 `}>
        <Dialog.Panel className="flex h-screen w-full flex-col bg-white" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <div className="relative flex w-full gap-2 bg-accent p-4  px-6 text-white">
            <button onClick={() => setShowDropDown(!showDropDown)}>
              <ChevronCorrectIcon className="sm-icon" open={showDropDown} data-sentry-element="ChevronCorrectIcon" data-sentry-source-file="index.tsx" />
            </button>
            {showDropDown && <DropDown closeDropDown={() => setShowDropDown(false)} selectItem={selectItem} className="absolute top-10 bg-offwhite text-offblack" items={items} filterOpen={showDropDown} />}
            <Dialog.Title data-sentry-element="unknown" data-sentry-source-file="index.tsx">{composeType}</Dialog.Title>
          </div>
          <ComposeHeaders data-sentry-element="ComposeHeaders" data-sentry-source-file="index.tsx" />
          <ComposerBody data-sentry-element="ComposerBody" data-sentry-source-file="index.tsx" />
          <SendButton refetch={refetch} data-sentry-element="SendButton" data-sentry-source-file="index.tsx" />
        </Dialog.Panel>
      </div>
    </Dialog>;
};
export default function WrappedComposerUI({
  composerTypeState,
  emailThreadId,
  subject,
  messageId,
  refetch,
  to
}: {
  composerTypeState: [string, (name: string) => void];
  refetch: () => void;
  emailThreadId?: string;
  subject?: string;
  messageId?: string;
  to?: string[];
}) {
  return <ComposerProvider messageId={messageId} emailThreadId={emailThreadId} subject={subject} to={to} initialComposeType={composerTypeState[0]} setInitialComposeType={composerTypeState[1]} refetch={refetch} data-sentry-element="ComposerProvider" data-sentry-component="WrappedComposerUI" data-sentry-source-file="index.tsx">
      <ComposerUI refetch={refetch} data-sentry-element="ComposerUI" data-sentry-source-file="index.tsx" />
    </ComposerProvider>;
}